<template>
  <div v-if="contents.status">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl my-5">
      <!--begin::Hero card-->
      <div class="card mb-12 shadow-sm">
        <!--begin::Hero body-->
        <div class="card-body flex-column p-5 mb-5">
          <!--begin::Hero content-->
          <div
            class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center h-lg-300px p-5 p-lg-15 overlay-wrapper gap-10"
          >
            <!--begin::Wrapper-->
            <div
              class="d-flex flex-column align-items-start justift-content-center flex-equal me-5 order-1 order-md-0"
            >
              <!--begin::Title-->
              <h1 class="fw-bolder fs-4 fs-lg-1 text-gray-800 mb-5 mb-lg-5">
                {{ contents.data.page.locale.title }}
              </h1>
              <!--end::Title-->
              <!--begin::Input group-->
              <div class="position-relative w-100 mb-5 mb-lg-10">
                <span class="text-gray-600 fs-6">{{
                  contents.data.page.locale.subTitle
                }}</span>
              </div>
              <!--end::Input group-->
              <!--begin::Action-->
              <a
                href="#"
                class="btn btn-primary fw-bolder fs-5"
                @click="setModalConfigs('create')"
                ><i
                  class="me-2 fs-4"
                  :class="`${contents.data.page.setting.buttonIcon}`"
                ></i
                >{{ contents.data.page.locale.buttonLabel }}</a
              >
              <!--end::Action-->
            </div>
            <!--end::Wrapper-->
            <!--begin::Wrapper-->
            <div
              class="flex-equal d-flex align-self-center justify-content-end align-items-center order-0 order-md-1"
            >
              <!--begin::Illustration-->
              <img
                :src="`${backendHost}/images/themes/${contentTheme}/${
                  $store.state.sidebar[contents.name].routeImage
                }`"
                alt=""
                class="mw-100 min-h-100px mh-md-125px mh-lg-225px"
              />
              <!--end::Illustration-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Hero content-->
        </div>
        <!--end::Hero body-->
      </div>
      <!--end::Hero card-->
      <div v-if="dataIsEmpty" class="p-20">
        <div class="d-flex flex-center">
          <img
            class="col-md-3"
            :src="`/assets/images/svg/no_data.svg`"
            alt=""
          />
        </div>
      </div>
      <!--begin::Row-->
      <div v-else class="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-5 g-xl-9">
        <!--begin::Col-->
        <div v-for="(blog, index) in blogs" :key="index" class="col-md-6">
          <!--begin::Card-->
          <div class="ribbon ribbon-top">
            <a
              class="ribbon-label fw-bolder"
              >{{blog.blogPublish ? "Published" : "Unpublished"}}<span class="ribbon-inner rounded-bottom" :class="blog.blogPublish ? 'bg-success' : 'bg-danger'"></span
            ></a>
          </div>
          <div class="card overlay overflow-hidden overlay-show">
            <div class="card-body p-0">
              <div class="overlay-wrapper">
                <div class="blog__template-img">
                  <!--begin::Image-->
                  <div
                    class="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-200px"
                    :style="`
                        background-image: url(${backendHost}/uploads/${
                      $store.state.uploadsPath
                    }/${JSON.parse(blog.blogBody).image});`"
                  ></div>
                  <!--end::Image-->
                </div>
              </div>
              <div
                class="overlay-layer flex-column align-items-stretch justify-content-between bg-dark bg-opacity-50 p-5"
              >
                <div class="text-white mt-3 px-2">
                  <i class="bi bi-eye-fill text-white me-1"></i>
                  <span>2.1k</span>
                </div>
                <div
                  class="d-flex flex-column text-center justify-content-center"
                >
                  <div
                    class="col text-white fw-bold fs-3 mb-5 turncating"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    :title="JSON.parse(blog.blogBody).title"
                  >
                    {{ JSON.parse(blog.blogBody).title }}
                  </div>
                  <div class="col d-flex">
                    <a
                      :href="`${backendHost}/blog/r/${blog.id}?locale=${blog.blogLocale}`" target="_blank"
                      class="col btn btn-sm btn-primary btn-shadow"
                      >{{ contents.data.page.card.buttons[0].label }}</a
                    >
                    <a
                      @click="setModalConfigs('update', index)"
                      class="col btn btn-sm btn-light-primary btn-shadow ms-2"
                      >{{ contents.data.page.card.buttons[1].label }}</a
                    >
                    <a
                      @click="setModalConfigs('delete', index)"
                      class="col btn btn-sm btn-light-danger btn-shadow ms-2"
                      >{{ contents.data.page.card.buttons[2].label }}</a
                    >
                  </div>
                </div>
                <div class="d-flex text-white mb-3 flex-center flex-stack">
                  <div class="">
                    <a
                      href="#"
                      class="d-flex align-items-center text-white text-decoration-none"
                    >
                      <i class="bi bi-person-circle text-white me-2"></i>
                      <div
                        class="turncating"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        :title="`${blog.profileFullName}`"
                      >
                        {{ blog.profileFullName }}
                      </div></a
                    >
                  </div>
                  <div class="d-flex">
                    <span class="text-white mx-1">On</span>
                    <div
                      class="fw-semiBold text-white"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      :title="``"
                    >
                      {{
                        new Date(blog.blogDate).toLocaleDateString(
                          routeLocale,
                          {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          }
                        )
                      }}
                    </div>
                  </div>
                </div>
                <!-- <div class="d-flex">
                  <div class="text-white position-absolute top-0 mt-3 px-2">
                    <i class="bi bi-eye-fill text-white me-1"></i>
                    <span>2.1k</span>
                  </div>
                  <div class="row text-center justify-content-center">
                    <div
                      class="text-white fw-bold fs-3 mb-5 turncating w-250px w-md-300px"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      :title="JSON.parse(blog.blogBody).title"
                    >
                      {{ JSON.parse(blog.blogBody).title }}
                    </div>
                    <div class="col-12">
                      <a
                        @click="setModalConfigs('show', index)"
                        class="btn btn-sm btn-primary btn-shadow"
                        >{{ contents.data.page.card.buttons[0].label }}</a
                      >
                      <a
                        @click="setModalConfigs('update', index)"
                        class="btn btn-sm btn-light-primary btn-shadow ms-2"
                        >{{ contents.data.page.card.buttons[1].label }}</a
                      >
                      <a
                        @click="deleteBlog(index)"
                        class="btn btn-sm btn-light-danger btn-shadow ms-2"
                        >{{ contents.data.page.card.buttons[2].label }}</a
                      >
                    </div>
                  </div>
                  <div
                    class="d-flex text-white position-absolute bottom-0 mb-3 flex-center"
                  >
                    <div class="d-flex">
                      <a
                        href="#"
                        class="col-5 col-lg-6 d-flex align-items-center text-white text-decoration-none"
                      >
                        <i class="bi bi-person-circle text-white me-2"></i>
                        <div
                          class="turncating"
                          data-bs-toggle="tooltip"
                          data-bs-placement="bottom"
                          title="BehzadRoohparvar"
                        >
                          BehzadRoohparvar
                        </div></a
                      >
                      <div class="col-8 d-flex">
                        <span class="text-white mx-1">On</span>
                        <div
                          class="fw-semiBold text-white"
                          data-bs-toggle="tooltip"
                          data-bs-placement="bottom"
                          title="2022-05-13, 4:42:46 p.m."
                        >
                          2022-05-13, 4:42:46 p.m.
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <!--end::Card-->
        </div>
        <div v-if="totalPage > 1" class="w-100 text-center mt-5">
          <pagination
          :pages="totalPages"
          v-model="currentPage"
          @update:modelValue="setCurrentPage"
          ></pagination>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->
      <!--begin::Modals-->
      <create-update
        v-if="modalDisplay"
        :contents="contents.data.modal"
        :modalInfo="blogInfo"
        :firstErrorName="firstErrorName"
        :modalType="'uploadselect'"
        @close-modal="setModalConfigs"
      ></create-update>
      <dialog-box
        v-if="dialogDisplay"
        :contents="contents.data.modal"
        :modalInfo="deleteInfo"
        @close-modal="setModalConfigs"
      >
      </dialog-box>
      <!--end::Modals-->
    </div>
    <!--end::Container-->
  </div>
</template>

<script>
import dataService from "../../../services/data";
import serverConfigs from "../../../configs/server.json";

// Tools
import Pagination from "../../tools/Pagination.vue";

// Modals
import CreateUpdate from "../../modals/CreateUpdate.vue";
import DialogBox from "../../modals/DialogBox.vue";

export default {
  components: {
    Pagination,
    CreateUpdate,
    DialogBox,
  },
  setup() {},
  data() {
    return {
      totalPages: 0,
      currentPage: 1,
      blogInfo: {},
      deleteInfo: {},
      modalDisplay: false,
      dialogDisplay: false,
      dataIsEmpty: true,
      blogs: [],
      loaderStatus: [false, false, false, false],
      firstErrorName: "",
      contents: {
        name: "",
        data: {},
        status: false,
      },
    };
  },
  computed: {
    contentTheme() {
      return this.$store.state.themeFile.replace(".css", "");
    },
    templateIndex() {
      let index = "";
      for (let b in this.contents.data.modal.fields[0].options) {
        if (
          this.contents.data.modal.fields[0].options[b].value ==
          this.blogInfo["blogTemplate"]
        ) {
          index = b;
        }
      }
      
      return index;
    },
    backendHost() {
      return serverConfigs["serverDomain"]["host"];
    },
    routeLocale() {
      return this.$route.params.locale;
    },
    bodyNode() {
      return document.getElementById("kt_body");
    },
  },
  methods: {
    setModalConfigs(opt, index) {
      this.$store.commit("setLoader", true);
      
      switch (opt) {
        case "update":
          this.blogInfo = { ...this.blogs[index], status: false };
          this.setupBlogBody(
            this.blogInfo,
            JSON.parse(
              this.contents.data.modal.fields[0].options[this.templateIndex]
                .fields
            )
          );
          this.modalDisplay = !this.modalDisplay;
          this.$store.commit("setLoader", false);
          
          break;
        case "create":
          this.setupBlogInfo();
          
          this.modalDisplay = !this.modalDisplay;
          this.$store.commit("setLoader", false);
          
          break;
        case "delete":
          this.deleteInfo = {
            id: this.blogs[index].id,
            name: JSON.parse(this.blogs[index].blogBody).title,
            status: false,
            delete: true,
          };
          this.dialogDisplay = !this.dialogDisplay;
          this.$store.commit("setLoader", false);
          
          break;
        case "validate":
          if (index.delete) {
            this.deleteBlog(index);
          } else {
            if (this.inputValidation(index)) {
              index.id ? this.updateBlog(index) : this.createNewBlog(index);
            } else {
              this.$store.commit("setLoader", false);
              
            }
          }
          break;
        case "show":
          window.open(
            `${serverConfigs["serverDomain"]["host"]}/blog/r/${this.blogs[index].id}`
          );
          this.$store.commit("setLoader", false);
          break;
        default:
          this.resetBlogInfo();
          this.$store.commit("setLoader", false);
          
          break;
      }
    },
    loaderDone() {
      if (
        this.loaderStatus[0] &&
        this.loaderStatus[1] &&
        this.loaderStatus[2] &&
        this.loaderStatus[3]
      ) {
        this.$store.commit("setLoader", false);
      }
    },
    setCurrentPage(page) {
      this.$store.commit("setLoader", true);
      this.readBlogs(page - 1)
    },
    getContents() {
      let data = {
        endPoint: `contents/r/blogs`,
        locale: `${this.routeLocale}`,
        data: {},
      };

      dataService
        .get(data)
        .then((res) => {
          this.contents = {
            name: res.data.data[0].contentName,
            data: res.data.data[0].contentJson,
            status: true,
          };
          this.readTemplates();
          this.readCategories();
          this.readTags();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[0] = true;
          this.loaderDone();
        });
    },
    setupBlogBody(obj) {
      let tempBody = {};
      Object.assign(tempBody, JSON.parse(obj["blogBody"]));
      obj["blogBody"] = [];
      for (let field in this.contents.data.modal.fields[0].options) {
        if (
          this.contents.data.modal.fields[0].options[field].fields != undefined
        ) {
          obj["blogBody"].push(
            JSON.parse(this.contents.data.modal.fields[0].options[field].fields)
          );
        }
      }
      for (let field in obj["blogBody"][`${this.templateIndex}`].fields) {
        obj["blogBody"][`${this.templateIndex}`].fields[field]["value"] =
          tempBody[
            `${obj["blogBody"][`${this.templateIndex}`].fields[field].name}`
          ];
      }
      obj["photos"] = [
        tempBody[`${obj["blogBody"][`${this.templateIndex}`].fields[0].name}`],
      ];
    },
    setupBlogInfo() {
      for (let counter in this.contents.data.modal.fields) {
        switch (this.contents.data.modal.fields[counter].type) {
          case "text":
            this.blogInfo[this.contents.data.modal.fields[counter].name] = "";
            break;
          case "combobox":
            this.blogInfo[this.contents.data.modal.fields[counter].name] = [];
            break;
          case "multiselect":
            this.blogInfo[this.contents.data.modal.fields[counter].name] = [];
            break;
          case "file":
            this.blogInfo[this.contents.data.modal.fields[counter].name] = [];
            break;
          case "imageInput":
            this.blogInfo[this.contents.data.modal.fields[counter].name] = [];
            break;
          case "component":
            this.blogInfo[this.contents.data.modal.fields[counter].name] = [];
            for (let field in this.contents.data.modal.fields[0].options) {
              if (
                this.contents.data.modal.fields[0].options[field].fields !=
                undefined
              ) {
                this.blogInfo[
                  this.contents.data.modal.fields[counter].name
                ].push(
                  JSON.parse(
                    this.contents.data.modal.fields[0].options[field].fields
                  )
                );
              }
            }
            break;
          default:
            break;
        }
      }
      this.blogInfo["status"] = true;
    },
    resetBlogInfo() {
      this.blogInfo = {};
      this.modalDisplay = false;
      this.dialogDisplay = false;
      if (!this.modalDisplay) {
        this.bodyNode.classList.remove("modal-open");
        this.bodyNode.style.overflow = "visible";
      }
      for (let index in this.contents.data.modal.fields) {
        if (this.contents.data.modal.fields[index].required) {
          this.contents.data.modal.fields[index].errorCode = 0;
        }
      }
    },
    inputValidation(obj) {
      let errorCount = 0;
      for (
        let index = 0;
        index < this.contents.data.modal.fields.length;
        index++
      ) {
        if (this.contents.data.modal.fields[index].required) {
          for (let expression in this.contents.data.modal.fields[index]
            .validation) {
            switch (
              this.contents.data.modal.fields[index].validation[expression]
                .expression
            ) {
              case "isEmpty":
                
                this.contents.data.modal.fields[index].errorCode =
                  obj[this.contents.data.modal.fields[index].name] !== null &&
                  (!obj[this.contents.data.modal.fields[index].name] ||
                    obj[this.contents.data.modal.fields[index].name].length ==
                      0)
                    ? this.contents.data.modal.fields[index].validation[
                        expression
                      ].id
                    : 0;
                if (this.contents.data.modal.fields[index].errorCode > 0) {
                  errorCount += 1;
                  this.firstErrorName = this.firstErrorName.length == 0 ? this.contents.data.modal.fields[index].name : "";
                }
                break;
              case "isNull":
                this.contents.data.modal.fields[index].errorCode =
                  this.contents.data.modal.fields[index].validation.length >
                    1 && this.contents.data.modal.fields[index].errorCode > 0
                    ? this.contents.data.modal.fields[index].errorCode
                    : obj[this.contents.data.modal.fields[index].name] == null
                    ? this.contents.data.modal.fields[index].validation[
                        expression
                      ].id
                    : 0;
                if (this.contents.data.modal.fields[index].errorCode > 0) {
                  errorCount += 1;
                  this.firstErrorName = this.firstErrorName.length == 0 ? this.contents.data.modal.fields[index].name : "";

                }
                break;
              case "isSpace":
                this.contents.data.modal.fields[index].errorCode =
                  this.contents.data.modal.fields[index].validation.length >
                    1 && this.contents.data.modal.fields[index].errorCode > 0
                    ? this.contents.data.modal.fields[index].errorCode
                    : String(
                        obj[this.contents.data.modal.fields[index].name]
                      ).trim().length == 0
                    ? this.contents.data.modal.fields[index].validation[
                        expression
                      ].id
                    : 0;
                if (this.contents.data.modal.fields[index].errorCode > 0) {
                  errorCount += 1;
                  this.firstErrorName = this.firstErrorName.length == 0 ? this.contents.data.modal.fields[index].name : "";

                }
                break;
              default:
                this.contents.data.modal.fields[index].errorCode = 0;
                break;
            }
          }
        }
      }

      let tempError = ""
      if (obj.blogTemplate && obj.blogTemplate !== "") {
        this.blogInfo = obj;
        obj["blogBody"][this.templateIndex].fields[0]["value"] =
          obj["photos"] && obj["photos"][0] ? obj["photos"][0] : "";
        for (
          let index = 0;
          index < obj.blogBody[this.templateIndex].fields.length;
          index++
        ) {
          if (obj.blogBody[this.templateIndex].fields[index].required) {
            for (let expression in obj.blogBody[this.templateIndex].fields[
              index
            ].validation) {
              switch (
                obj.blogBody[this.templateIndex].fields[index].validation[
                  expression
                ].expression
              ) {
                case "isEmpty":
                  obj.blogBody[this.templateIndex].fields[index].errorCode =
                    !obj.blogBody[this.templateIndex].fields[index].value ||
                    obj.blogBody[this.templateIndex].fields[index].value
                      .length == 0
                      ? obj.blogBody[this.templateIndex].fields[index]
                          .validation[expression].id
                      : 0;
                  if (
                    obj.blogBody[this.templateIndex].fields[index].errorCode > 0
                  ) {
                    errorCount += 1;
                  tempError = tempError.length == 0 ? obj.blogBody[this.templateIndex].fields[index].name : tempError;

                  }
                  break;
                case "isNull":
                  obj.blogBody[this.templateIndex].fields[index].errorCode =
                    obj.blogBody[this.templateIndex].fields[index].validation
                      .length > 1 &&
                    obj.blogBody[this.templateIndex].fields[index].errorCode > 0
                      ? obj.blogBody[this.templateIndex].fields[index].errorCode
                      : obj.blogBody[this.templateIndex].fields[index].value ==
                        null
                      ? obj.blogBody[this.templateIndex].fields[index]
                          .validation[expression].id
                      : 0;
                  if (
                    obj.blogBody[this.templateIndex].fields[index].errorCode > 0
                  ) {
                    errorCount += 1;
                    tempError = tempError.length == 0 ? obj.blogBody[this.templateIndex].fields[index].name : tempError;
                  }
                  break;
                case "isSpace":
                  obj.blogBody[this.templateIndex].fields[index].errorCode =
                    obj.blogBody[this.templateIndex].fields[index].validation
                      .length > 1 &&
                    obj.blogBody[this.templateIndex].fields[index].errorCode > 0
                      ? obj.blogBody[this.templateIndex].fields[index].errorCode
                      : obj.blogBody[this.templateIndex].fields[index].value ==
                        " "
                      ? obj.blogBody[this.templateIndex].fields[index]
                          .validation[expression].id
                      : 0;
                  if (
                    obj.blogBody[this.templateIndex].fields[index].errorCode > 0
                  ) {
                    errorCount += 1;
                    tempError = tempError.length == 0 ? obj.blogBody[this.templateIndex].fields[index].name : tempError;
                  }
                  break;
                default:
                  obj.blogBody[this.templateIndex].fields[index].errorCode = 0;
                  break;
              }
            }
          }
        }
      }
      this.firstErrorName = tempError.length > 0 ? tempError : this.firstErrorName
      return errorCount == 0 ? true : false;
    },
    readTemplates() {
      let data = {
        endPoint: `templates`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {},
        query: {
          category: "Blogs",
        },
      };
      data.query = JSON.stringify(data.query)
        .replace("{", "")
        .replace(",", "&")
        .replace("}", "")
        .replace(/":"/g, "=")
        .replace(/"/g, "");
      dataService
        .get(data)
        .then((res) => {
          
          let tempTemplates = this.contents.data.modal.fields[0];
          for (let template in res.data.data) {
            
            tempTemplates.options.push({
              title: res.data.data[template].templateName,
              value: res.data.data[template].id,
              fields: JSON.stringify(res.data.data[template].templateJson),
              parse: res.data.data[template].templateJson.mockup,
            });
          }
        })
        .catch((error) => {
          
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[1] = true;
          this.loaderDone();
        });
    },
    readCategories() {
      let data = {
        endPoint: `categories`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          let tempTags = this.contents.data.modal.fields[2];
          for (let tag in res.data.data) {
            tempTags.options.push({
              title: res.data.data[tag].categoryName,
              value: res.data.data[tag].id,
            });
          }
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[2] = true;
          this.loaderDone();
        });
    },
    readTags() {
      let data = {
        endPoint: `tags`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          let tempTags = this.contents.data.modal.fields[3];
          for (let tag in res.data.data) {
            tempTags.options.push({
              title: res.data.data[tag].tagName,
              value: res.data.data[tag].id,
            });
          }
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[2] = true;
          this.loaderDone();
        });
    },
    createNewBlog(obj) {
      
      this.blogInfo = obj;
      obj["blogBody"][`${this.templateIndex}`].fields[0]["value"] =
        obj["photos"][0];
      let tempBody = {};
      for (let field in obj["blogBody"][`${this.templateIndex}`].fields) {
        tempBody[
          `${obj["blogBody"][`${this.templateIndex}`].fields[field].name}`
        ] = obj["blogBody"][`${this.templateIndex}`].fields[field].value;
      }
      
      let data = {
        endPoint: `blogs`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {
          template: obj["blogTemplate"],
          // locale: obj["blogLocale"],
          body: tempBody,
          locale: this.routeLocale,
          category: obj["blogCategory"],
          tags: obj["blogTags"],
          summary: obj["blogSummary"],
          publish: obj["blogPublish"],
        },
      };
      dataService
        .post(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          this.resetBlogInfo();
          this.readBlogs();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("setLoader", false);
          
        });
    },
    readBlogs(page) {
      let data = {
        endPoint: `blogs`,
        pageNo: page,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          res.data.data.length > 0
            ? (this.dataIsEmpty = false)
            : (this.dataIsEmpty = true);
          this.blogs = res.data.data;
          this.blogs = this.blogs.reverse();
          if (page == 0) {
            
            this.totalPages = res.data.totalRes
          }
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[3] = true;
          this.loaderDone();
        });
    },
    updateBlog(obj) {
      
      obj["blogBody"][`${this.templateIndex}`].fields[0]["value"] =
        obj["photos"] && obj["photos"].length > 0
          ? obj["photos"][0]
          : obj["blogBody"][`${this.templateIndex}`].fields[0]["value"];
      let tempBody = {};
      for (let field in obj["blogBody"][`${this.templateIndex}`].fields) {
        tempBody[
          `${obj["blogBody"][`${this.templateIndex}`].fields[field].name}`
        ] = obj["blogBody"][`${this.templateIndex}`].fields[field].value;
      }
      let data = {
        endPoint: `blogs/u/${obj.id}`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {
          template: obj["blogTemplate"],
          // locale: obj["blogLocale"],
          body: tempBody,
          locale: this.routeLocale,
          category: obj["blogCategory"],
          tags: obj["blogTags"],
          summary: obj["blogSummary"],
          publish: obj["blogPublish"],
        },
      };
      
      dataService
        .post(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          this.resetBlogInfo();
          this.readBlogs();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("setLoader", false);
          
        });
    },
    deleteBlog(obj) {
      this.$store.commit("setLoader", true);
      
      let data = {
        endPoint: `blogs/d/${obj.id}`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          this.resetBlogInfo();
          this.readBlogs();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("setLoader", false);
          
        });
    },
  },
  mounted() {
    this.$store.commit("setLoader", true);
    this.getContents();
    this.readBlogs(0);
  },
};
</script>